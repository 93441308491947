export const LOGIN_ROUTE = '/auth'

export const MAX_FILE_SIZE = 5 * 1024 * 1024; 

export const BULK_UPLOAD_FILE_TYPES = [".xlsx"];

export const LANGUAGES = [{name:'English',value:'en'},{name:'ಕನ್ನಡ',value:'kn'}]

export const MEDIUMS = [{name:'English',value:'english'}, {name:'Kannada',value:'kannada'}]

export const CLASS_OPTIONS = [1,2,3,4,5,6,7,8,9,10]

export const LOADER_RESTRICTED_URLS = [
    '/chapter/list',
    '/master-lesson/list/',
    '/resource-plan/list/',
    '/teacher-lesson-plan/list',
    '/auth/me',
    '/chat/',
    '/lessonchat/'
]

export const IDLE_START_THRESHOLD = 180;

export const IDLE_WARNING_THRESHOLD = 1620;

export const INTERACTION_LOG_THRESHOLD = 10;